import { Outlet } from '@remix-run/react';

import { AdminView } from '../pages/Admin/AdminView';
import { Header, type NavItem } from '../pages/Admin/Header';

function ProgramsLayoutRoute() {
  const links: Record<string, NavItem> = {
    Programs: '/admin/programs/v2',
    'Global Pairing Tournaments': '/admin/programs/global-pairing-rounds',
    'Global Promos Config': '/admin/programs/global-promotions',
    'Birthdays and Anniversaries': '/admin/programs/celebrations',
    Analytics: {
      'Installed Programs': '/admin/programs/links',
      'Birthdays & Anniversaries': '/admin/programs/celebrations/list',
    },
  };

  return (
    <AdminView>
      <div className='w-full h-full'>
        <div className='py-10 px-10'>
          <Header links={links} />
        </div>
        <Outlet />
      </div>
    </AdminView>
  );
}

export const Component = ProgramsLayoutRoute;

export function clientLoader() {
  return null;
}
